import { useMemo } from 'react';
import { type ConnectDragSource } from 'react-dnd';
import { useFieldArray } from 'react-hook-form';
import Select from 'react-select';

import {
  type ClientButton,
  ClientEmailButtonStyle,
} from '@lp-lib/api-service-client/public';

import { useInstance } from '../../../hooks/useInstance';
import { uuidv4 } from '../../../utils/common';
import { buildReactSelectStyles } from '../../../utils/react-select';
import { DragDropList } from '../../common/DragDrop';
import { type Option } from '../../common/Utilities';
import { DeleteIcon } from '../../icons/DeleteIcon';
import { MenuIcon } from '../../icons/MenuIcon';
import { type MessageTemplateFormData } from './Shared';

const STYLE_CONFIG = {
  [ClientEmailButtonStyle.EMAILBUTTONSTYLE_DEFAULT]: {
    label: 'Default',
    value: ClientEmailButtonStyle.EMAILBUTTONSTYLE_DEFAULT,
  },
  [ClientEmailButtonStyle.EMAILBUTTONSTYLE_PRIMARY]: {
    label: 'Primary',
    value: ClientEmailButtonStyle.EMAILBUTTONSTYLE_PRIMARY,
  },
  [ClientEmailButtonStyle.EMAILBUTTONSTYLE_DANGER]: {
    label: 'Danger',
    value: ClientEmailButtonStyle.EMAILBUTTONSTYLE_DANGER,
  },
};

function MessageTemplateEmailButtonStyleSelect(props: {
  value: ClientEmailButtonStyle;
  onChange: (value: ClientEmailButtonStyle) => void;
}) {
  const { value, onChange } = props;

  const options = useMemo(() => Object.values(STYLE_CONFIG), []);
  const styles = useInstance(() =>
    buildReactSelectStyles<Option<ClientEmailButtonStyle>>({
      override: { control: { height: 48 } },
    })
  );

  return (
    <Select<Option<ClientEmailButtonStyle>>
      options={options}
      value={STYLE_CONFIG[value]}
      classNamePrefix='select-box-v2'
      className='w-full'
      styles={styles}
      onChange={(option) => {
        if (!option) return;
        onChange(option.value);
      }}
    />
  );
}

function MessageTemplateEmailButtonActionSelect() {
  const options = useMemo(() => [{ label: 'Open URL', value: 'open_url' }], []);
  const styles = useInstance(() =>
    buildReactSelectStyles<Option<string>>({
      override: { control: { height: 48 } },
    })
  );

  return (
    <Select<Option<string>>
      options={options}
      value={options[0]}
      classNamePrefix='select-box-v2'
      className='w-full'
      styles={styles}
    />
  );
}

function MessageTemplateEmailButtonEditor(props: {
  button: ClientButton;
  drag: ConnectDragSource;
  onChange: (updates: Partial<ClientButton>) => void;
  onDelete: () => void;
}) {
  const { button, drag, onChange, onDelete } = props;

  return (
    <div className='w-full flex gap-2'>
      <div className='flex-1 bg-main-layer rounded-lg p-3 flex items-center gap-2'>
        <button type='button' ref={drag} className='btn cursor-move'>
          <MenuIcon />
        </button>

        <div className='flex-1 flex flex-col gap-2'>
          <div className='flex items-center gap-2'>
            <label className='w-1/2'>
              <div className='text-3xs font-medium text-icon-gray mb-1'>
                Function
              </div>
              <MessageTemplateEmailButtonActionSelect />
            </label>
            <label className='w-1/2'>
              <div className='text-3xs font-medium text-icon-gray mb-1'>
                Style
              </div>
              <MessageTemplateEmailButtonStyleSelect
                value={button.style}
                onChange={(value) => onChange({ style: value })}
              />
            </label>
          </div>
          <label className='w-full'>
            <div className='text-3xs font-medium text-icon-gray mb-1'>Text</div>
            <input
              value={button.text}
              onChange={(e) => onChange({ text: e.target.value })}
              className={`field w-full mb-0`}
              placeholder='Text'
            />
          </label>

          <label className='w-full'>
            <div className='text-3xs font-medium text-icon-gray mb-1'>Href</div>
            <input
              value={button.href}
              onChange={(e) => onChange({ href: e.target.value })}
              className={`field w-full mb-0`}
              placeholder='https://lunapark.com'
            />
          </label>
        </div>
      </div>
      <button
        type='button'
        onClick={onDelete}
        className='w-7.5 h-7.5 rounded-lg border border-secondary btn flex justify-center items-center text-red-002 bg-black'
      >
        <DeleteIcon />
      </button>
    </div>
  );
}

export function MessageTemplateEmailButtonsField() {
  const {
    fields: buttons,
    append,
    remove,
    move,
    update,
  } = useFieldArray<MessageTemplateFormData, 'template.email.buttons', 'key'>({
    name: 'template.email.buttons',
    keyName: 'key',
  });

  const handleAdd = () => {
    append({
      id: uuidv4(),
      style: ClientEmailButtonStyle.EMAILBUTTONSTYLE_DEFAULT,
      text: '',
      href: '',
    });
  };

  const handleUpdate = (index: number, updates: Partial<ClientButton>) => {
    update(index, {
      ...buttons[index],
      ...updates,
    });
  };

  return (
    <div className='w-full'>
      <div className='mb-2 font-bold'>Buttons</div>
      <div className='w-full flex flex-col gap-2'>
        <DragDropList
          type='message-template-email-buttons'
          items={buttons}
          onMove={move}
          render={({ item, index, drag, ref, style }) => (
            <div className={`w-full`} ref={ref} style={style}>
              <MessageTemplateEmailButtonEditor
                button={item}
                drag={drag}
                onChange={(updates) => handleUpdate(index, updates)}
                onDelete={() => remove(index)}
              />
            </div>
          )}
        />
      </div>
      <button
        type='button'
        className='mt-2 btn text-sms text-secondary'
        onClick={handleAdd}
      >
        + Add Button
      </button>
    </div>
  );
}
