import { Markdown, render } from '@react-email/components';
import { markdownToBlocks } from '@totoday/mack';
import { useEffect, useRef, useState } from 'react';
import React from 'react';
import { type FieldError } from 'react-hook-form';

import { type Media, type MediaData } from '@lp-lib/media';

import { uuidv4 } from '../../../utils/common';
import { MediaUtils } from '../../../utils/media';
import { MiniMediaEditor } from '../../MediaUploader/MiniMediaEditor';

export function MessageTemplateMarkdownInput(props: {
  value: string;
  onChange: (value: string) => void;
  title?: React.ReactNode;
  vars?: Record<string, string>;
  error?: FieldError | null;
}) {
  const { value, onChange, title = 'Body', vars, error } = props;

  const ref = useRef<HTMLTextAreaElement>(null);
  const [mediaKey, setMediaKey] = useState<string>(uuidv4());

  useEffect(() => {
    if (ref.current) {
      ref.current.style.height = 'auto';
      ref.current.style.height = ref.current.scrollHeight + 'px';
    }
  }, [value]);

  const handleUploadMedia = (_: MediaData | null, media: Media | null) => {
    if (!media) return;

    const url = MediaUtils.PickMediaUrl(media);
    const image = `![alt text](${url})`;

    const newText = value ? `${value}\n${image}\n\n` : `${image}\n\n`;
    onChange(newText);
    setMediaKey(uuidv4());
  };

  return (
    <div className='w-full'>
      <div className='mb-2 font-bold'>{title}</div>
      <textarea
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className={`${
          error ? 'field-error' : 'field'
        } min-h-72 h-auto p-5 mb-0 scrollbar`}
        ref={ref}
        placeholder='Type your message here...'
      ></textarea>
      {vars && (
        <div className='mt-1 ml-1 text-icon-gray text-3xs font-medium text-left'>
          {`Variables: ${Object.keys(vars).join(', ')}`}
        </div>
      )}
      <div
        className={`mt-1 ml-1 text-icon-gray text-3xs font-medium text-left`}
      >
        {`**bold** *italics* ~~strike~~ [Luna Park](https://lunapark.com)`}
      </div>

      <div className='mt-2 w-[fit] flex'>
        <MiniMediaEditor
          key={mediaKey}
          id='message-template-markdown-input'
          video={false}
          media={null}
          mediaData={null}
          objectFit='cover'
          onChange={handleUploadMedia}
        />
      </div>
    </div>
  );
}

export const MessageTemplateMarkdownRender = React.memo(function (props: {
  text?: string | null;
}) {
  if (!props.text) return null;

  return (
    <Markdown
      markdownCustomStyles={{
        p: {
          margin: '1em 0',
          fontSize: '13px',
        },
        image: {
          width: '100%',
          height: 'auto',
        },
        blockQuote: {
          background: '#f9f9f9',
          borderLeft: '10px solid #ccc',
          margin: '1.5em 10px',
          padding: '1em 10px',
          color: 'black',
        },
        ul: {
          listStyle: 'disc',
        },
        ol: {
          listStyle: 'decimal',
          padding: '0 0 10px 0',
        },
        li: {
          margin: '0 0 0 20px',
        },
      }}
      markdownContainerStyles={{
        background: 'black',
        color: 'white',
        fontFamily: 'Arial',
      }}
    >
      {props.text}
    </Markdown>
  );
});

export function MessageTemplateMarkdownToHTMLRender(props: { text: string }) {
  const html = render(<MessageTemplateMarkdownRender text={props.text} />, {
    pretty: true,
  });

  return (
    <div className='w-full h-full overflow-x-scroll whitespace-pre text-sms'>
      {html}
    </div>
  );
}

export function MessageTemplateMarkdownToSlackBlocksRender(props: {
  text?: string | null;
}) {
  const [blocks, setBlocks] = useState<string>('');

  useEffect(() => {
    if (!props.text) {
      setBlocks('');
      return;
    }
    markdownToBlocks(props.text).then((blocks) =>
      setBlocks(JSON.stringify(blocks, null, ' '))
    );
  }, [props.text]);

  return (
    <div className='w-full h-full overflow-x-scroll whitespace-pre text-sms'>
      {blocks}
    </div>
  );
}
